import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link as LinkReact, Element } from "react-scroll";

import "tachyons/css/tachyons.min.css";
import "../css/common.css";
import "../css/pages/special-freight.css";

import Header from "../components/Header";
import TitlePage from "../components/ui/TitlePage";
import SectionTextPage from "../components/ui/SectionTextPage";
import Footer from "../components/Footer";
import SectionImagePlayer from "../components/ui/SectionImagePlayer";
import ListText from "../components/ui/ListText";
import ReadMore from "../components/ReadMore";
import Seo from "../components/SeoComponent";

/**
 * Display a link anchor for the page transport
 * @param {linkAnchor} linkAnchor
 */
const SpecialFreightLinkAnchor = props => {
  return (
    <LinkReact smooth={true} duration={500} to={props.linkAnchor.anchor}>
      <div
        className={`${
          props.linkAnchor.index === 1 ? "bt" : ""
        } bb b--black-20 pv3 flex flex-row justify-between pointer dim linkAnchor`}
      >
        <div>
          {props.linkAnchor.index} - {props.linkAnchor.title}
        </div>
        <div>
          <img src="/icons/arrow-right.svg" alt="arrow" />
        </div>
      </div>
    </LinkReact>
  );
};

const SpecialFreightSectionAnchor = props => {
  return (
    <Element name={props.linkAnchor.anchor}>
      <SectionTextPage>
        <p className="transport-subtitle">
          {props.linkAnchor.index} - {props.linkAnchor.title}
        </p>
        {props.children}
      </SectionTextPage>
    </Element>
  );
};

/**
 * Display a paragraph with background black and uppercase
 * @param {*} props
 */
const TransportSpecialParaph = props => {
  return <p className="bg-black white pa3 ttu">{props.children}</p>;
};

/**
 * Page Transport
 */
const SpecialFreight = () => {
  const { t } = useTranslation();

  const linksAnchor = [
    { index: 1, anchor: "ts1", title: t("page-transport.ts1.title") },
    { index: 2, anchor: "ts2", title: t("page-transport.ts2.title") },
    { index: 3, anchor: "ts3", title: t("page-transport.ts3.title") },
    { index: 4, anchor: "ts4", title: t("page-transport.ts4.title") },
    { index: 5, anchor: "ts5", title: t("page-transport.ts5.title") }
  ];

  return (
    <>
      <Seo title={t("page-transport.metadata.name")} description={t("page-transport.metadata.description")} />
      <Header />
      <SectionTextPage>
        <TitlePage
          category={t("page-transport.category")}
          title={t("page-transport.title")}
          style={{ paddingLeft: 0 }}
        />
        <p>{t("page-transport.p1")}</p>
        <p>{t("page-transport.p2")}</p>
        <p>{t("page-transport.p3")}</p>

        <div className="flex flex-column mt4">
          {linksAnchor.map((linkAnchor, index) => {
            return <SpecialFreightLinkAnchor linkAnchor={linkAnchor} key={index} />;
          })}
        </div>
      </SectionTextPage>

      <SectionImagePlayer imageHD="transport-1.jpg" />

      <SpecialFreightSectionAnchor linkAnchor={linksAnchor[0]}>
        <p>{t("page-transport.ts1.p1")}</p>
        <TransportSpecialParaph>{t("page-transport.ts1.p2")}</TransportSpecialParaph>
        <p>{t("page-transport.ts1.p3")}</p>
        <ListText text={t("page-transport.ts1.l1")} />
        <ListText text={t("page-transport.ts1.l2")} />
        <ListText text={t("page-transport.ts1.l3")} />
        <ListText text={t("page-transport.ts1.l4")} />
        <ListText text={t("page-transport.ts1.l5")} />
        <p>{t("page-transport.ts1.p4")}</p>
        <p>{t("page-transport.ts1.p5")}</p>
        <p>{t("page-transport.ts1.p6")}</p>
        <TransportSpecialParaph>{t("page-transport.ts1.p7")}</TransportSpecialParaph>
        <p>{t("page-transport.ts1.p8")}</p>
        <p>{t("page-transport.ts1.p9")}</p>
        <p>{t("page-transport.ts1.p10")}</p>
        <SectionImagePlayer imageHD="transport-2.jpg" className="w-100" />
      </SpecialFreightSectionAnchor>

      <SpecialFreightSectionAnchor linkAnchor={linksAnchor[1]}>
        <p>{t("page-transport.ts2.p1")}</p>
        <TransportSpecialParaph>{t("page-transport.ts2.p2")}</TransportSpecialParaph>
        <p>{t("page-transport.ts2.p3")}</p>
        <p>{t("page-transport.ts2.p4")}</p>
        <p className="section-subtitle">{t("page-transport.ts2.p5")}</p>
        <ListText text={t("page-transport.ts2.l1")} />
        <ListText text={t("page-transport.ts2.l2")} />
        <ListText text={t("page-transport.ts2.l3")} />
        <ListText text={t("page-transport.ts2.l4")} />
        <TransportSpecialParaph>{t("page-transport.ts2.p6")}</TransportSpecialParaph>
        <p>{t("page-transport.ts2.p7")}</p>
        <p>{t("page-transport.ts2.p8")}</p>
        <p dangerouslySetInnerHTML={{ __html: t("page-transport.ts2.p9") }}></p>
        <SectionImagePlayer imageHD="transport-3.jpg" className="w-100" />
      </SpecialFreightSectionAnchor>

      <SpecialFreightSectionAnchor linkAnchor={linksAnchor[2]}>
        <p>{t("page-transport.ts3.p1")}</p>
        <TransportSpecialParaph>{t("page-transport.ts3.p2")}</TransportSpecialParaph>
        <p>{t("page-transport.ts3.p3")}</p>
        <p>{t("page-transport.ts3.p4")}</p>
        <TransportSpecialParaph>{t("page-transport.ts3.p5")}</TransportSpecialParaph>
        <p>{t("page-transport.ts3.p6")}</p>
        <p>{t("page-transport.ts3.p7")}</p>
        <SectionImagePlayer imageHD="transport-4.jpg" className="w-100" />
      </SpecialFreightSectionAnchor>

      <SpecialFreightSectionAnchor linkAnchor={linksAnchor[3]}>
        <p>{t("page-transport.ts4.p1")}</p>
        <p>{t("page-transport.ts4.p2")}</p>
        <p>{t("page-transport.ts4.p3")}</p>
        <SectionImagePlayer imageHD="transport-5.jpg" className="w-100" />
      </SpecialFreightSectionAnchor>

      <SpecialFreightSectionAnchor linkAnchor={linksAnchor[4]}>
        <p>{t("page-transport.ts5.p1")}</p>
        <TransportSpecialParaph>{t("page-transport.ts5.p2")}</TransportSpecialParaph>
        <p>{t("page-transport.ts5.p3")}</p>
        <p>{t("page-transport.ts5.p4")}</p>
        <SectionImagePlayer imageHD="transport-6.jpg" className="w-100" />
      </SpecialFreightSectionAnchor>

      <ReadMore title={t("page-transport.readmore")} news={[]} />

      <Footer />
    </>
  );
};

export default SpecialFreight;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
